<script setup>
import Layout from "../../layouts/main.vue";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref } from "vue";
import DataForm from "./dataFormQuality";
import { FormInstance } from "element-plus";
import { useRouter, useRoute } from "vue-router";
const rolesAccount = ref("");
// ngôn ngữ -> bắt buộc
import en from "element-plus/lib/locale/lang/en";
import vi from "element-plus/lib/locale/lang/vi";

// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import toastr from "toastr";
import Swal from "sweetalert2";
import LanguageService from "../../service/LanguageService";

const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const ruleFormRef = ref(FormInstance);
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
const listThreshold = reactive({ value: [] });
let loading = ref(true); // loading bảng
const router = useRouter();
const route = useRoute();

// function
// lấy về danh sách
const getQualityLevel = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields:
      "id,level,threshold_name,lower_threshold,higher_threshold,note,disabled",
    limit: 100,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "QualityLevel",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});

  console.log("aaaaaaaaaaa", dataFilter);

  try {
    const response = await mushroom.quality_level.listAsync(dataFilter, {
      cacheAge: 1,
    });
    console.log("Các quality_level thỏa mãn: %o, info: %o", response);
    if (response.result) {
      loading.value = false;
      // lengthProvince.value = response.result.length;
      // let res = await changeData(response.result);
      // dataTables.value = res;
      dataTables.value = response.result;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

const getQualityName = async () => {
  try {
    const response = await mushroom.quality_level.listAsync();
    console.log("Các quality_level thỏa mãn: %o, info: %o", response);
    listThreshold.value = response.result.map((item) => item.threshold_name);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "QualityLevel",
    query: query,
  });
};

// tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      loading.value = true;
      tableRules.dataSearch.value["level"] = tableRules.dataSearch.value[
        "level"
      ]
        ? tableRules.dataSearch.value["level"].trim()
        : "";
      tableRules.dataSearch.value["threshold_name"] = tableRules.dataSearch
        .value["threshold_name"]
        ? tableRules.dataSearch.value["threshold_name"].trim()
        : "";

      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      tableRules.offset = 0;
      tableRules.page = 1;
      getQualityLevel();
    } else return false;
  });
};

const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  getQualityLevel();
};

const fn_CreateQuality = () => {
  router.push({ name: "CreateQuality" });
  // router.push({ name: "CauHinhQuality" });
};

const fn_hashUrl = () => {
  if (route.query.limit) {
    tableRules.limit = parseInt(route.query.limit);
  }
  if (route.query.page) {
    tableRules.page = parseInt(route.query.page);
  }
  if (route.query.limit && route.query.page) {
    tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  }
  if (route.query.sort) {
    tableRules.sort = route.query.sort;
    tableRules.defaultSort[0] = tableRules.sort;
  }
  if (tableRules.sort.indexOf("-") != -1) {
    tableRules.defaultSort[1] = "descending";
  } else {
    tableRules.defaultSort[1] = "ascending";
  }
  if (route.query.filters) {
    tableRules.filters = route.query.filters;
    // string to object
    tableRules.dataSearch.value = MethodService.stringToObject(
      tableRules.filters
    );
  }
  if (route.query.showFormSearch) {
    tableRules.showFormSearch = route.query.showFormSearch;
  }
};

const handleClick = (id) => {
  router.push({
    name: "UpdateQuality",
    query: { id: id },
  });
};

const deleteQuality = async (id) => {
  if (!id) return;
  Swal.fire({
    title: LanguageService[lang.value].t_warning,
    text:
      LanguageService?.lang?.toastr_confirm_delete_threshold ??
      "toastr_confirm_delete_threshold",
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    confirmButtonText: LanguageService[lang.value].swal_accept,
    cancelButtonText: LanguageService[lang.value].swal_cancel,
    showCancelButton: true,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await await mushroom.quality_level.deleteAsync({
          id: id, // required
        });
        console.log("Thông tin table_config trả về: %o", response);
        toastr.success(
          LanguageService?.lang?.toastr_delete_threshold_success ??
            "toastr_delete_threshold_success"
        );
        getQualityLevel();
      } catch (e) {
        toastr.error(
          LanguageService?.lang?.toastr_delete_threshold_fail ??
            "toastr_delete_threshold_fail"
        );
        console.error("Có lỗi: %o", e);
      }
    }
  });
};

onMounted(async () => {
  const response = await mushroom.$auth.meAsync();
  rolesAccount.value = response.result.roles[0];
  getQualityName();
  fn_hashUrl();
  getQualityLevel();
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header" style=" display: flex, justify: space-between ">
        <h3 class="text-uppercase mb-0">{{ $t("t-list-quality-level") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              style="margin-right: 8px"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_CreateQuality"
              v-if="rolesAccount === 'Admin'"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-config-provider
                  :locale="lang_web.showLang == 'vi' ? vi : en"
                >
                  <el-form
                    ref="ruleFormRef"
                    :model="tableRules.dataSearch.value"
                    :rules="validForm.value"
                    label-position="top"
                    @submit.prevent
                  >
                    <b-row>
                      <b-col>
                        <el-form-item label="Level" prop="level">
                          <el-input
                            type="number"
                            clearable
                            v-model="tableRules.dataSearch.value['level']"
                            :placeholder="`${$t('t-place-level')}`"
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item :label="`${$t('t-threshold-name')}`">
                          <el-select
                            v-model="
                              tableRules.dataSearch.value['threshold_name']
                            "
                            filterable
                            clearable
                            :placeholder="`${$t('t-choose-threshold-name')}`"
                            :no-match-text="$t('t-match-data')"
                            :no-data-text="$t('t-no-data')"
                          >
                            <el-option
                              v-for="item in listThreshold.value"
                              :key="item"
                              :label="item"
                              :value="item"
                            />
                          </el-select>
                        </el-form-item>
                      </b-col>
                    </b-row>
                    <div class="text-center">
                      <a
                        class="btn btn-soft-secondary btn-border mx-3"
                        style="width: 80px"
                        @click="fn_showFormTimKiem()"
                      >
                        {{ $t("t-hide") }}
                      </a>
                      <button
                        class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                        style="width: 100px"
                        native-type="submit"
                        @click="fn_TimKiem(ruleFormRef)"
                      >
                        {{ $t("t-search") }}
                      </button>
                    </div>
                  </el-form>
                </el-config-provider>
              </b-card>
            </b-collapse>
          </div>
          <el-config-provider :locale="lang_web.showLang == 'vi' ? vi : en">
            <el-table
              size="small"
              border
              stripe
              :data="dataTables.value"
              style="width: 100%, ov"
              v-loading="loading"
              :empty-text="$t('t-no-data')"
              @sort-change="fn_tableSortChange"
              :default-sort="{
                prop: tableRules.defaultSort[0],
                order: tableRules.defaultSort[1],
              }"
            >
              <el-table-column label="STT" width="60" align="center">
                <template #default="scope">
                  <div>
                    {{ scope.$index + tableRules.offset + 1 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="level"
                :label="`${$t('t-level')}`"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="threshold_name"
                :label="`${$t('t-threshold-name')}`"
                align="center"
                width="120"
              ></el-table-column>
              <el-table-column
                prop="lower_threshold"
                :label="`${$t('t-lower-threshold')}`"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="higher_threshold"
                :label="`${$t('t-higher-threshold')}`"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="note"
                :label="`${$t('t-note')}`"
                width="400"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="disabled"
                :label="`${$t('t-disabled')}`"
                align="center"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.disabled"
                    class="badge badge-soft-success text-uppercase"
                    >{{ $t("t-true") }}</span
                  >
                  <span
                    v-if="scope.row.disabled === false"
                    class="badge badge-soft-danger text-uppercase"
                  >
                    {{ $t("t-false") }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="`${$t('t-show-district')}`"
                width="140"
                align="center"
              >
                <template #default="scope">
                  <div class="btn-group-thao-tac">
                    <el-tooltip
                      :content="$t('action_option')"
                      effect="light"
                      placement="top"
                    >
                      <button
                        type="button"
                        class="btn btn-soft-success waves-effect waves-light btn-sm"
                        @click="handleClick(scope.row.id)"
                      >
                        <i class="ri-edit-box-line"></i>
                      </button>
                    </el-tooltip>
                    <el-tooltip
                      :content="$t('action_delete')"
                      effect="light"
                      placement="top"
                      v-if="rolesAccount === 'Admin'"
                    >
                      <el-button
                        type="button"
                        class="btn btn-soft-danger waves-effect waves-light btn-sm"
                        @click="deleteQuality(scope.row.id)"
                      >
                        <i class="ri-delete-bin-6-line"></i>
                      </el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-config-provider>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
a {
  color: unset;
}
.el-table {
  height: calc(100vh - 200px);
}
</style>
